<template class="mt-5">
  <div class="custom-quillEditor">
    <quill-editor :options="editorOption" v-model="model" ref="quillEditor" @blur="tellBlurOccurred"> </quill-editor>
  </div>
</template>
<script>
import { Quill, quillEditor } from "vue-quill-editor";
import ImageResize from "quill-image-resize-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Quill.register("modules/imageResize", ImageResize);

const colors = [
  "#ececec", "#aaaaaa", "#373737", "#9fc2fb", "#1b6ef3", "#244373", "#a5afd2", "#304799", "#162866", "#baeeff", "#00cfff", "#0573a3", "#d1a2ff", "#9036ea", "#5405a3", "#ffa2e5", "#ea36b7", "#a30576", "#aafacc", "#46bc79", "#1a5d39", "#fff4cd", "#f2c94c", "#b98621", "#ffc0c0", "#f57070", "#9b1d1d", "#0d0f11", "#595959", "#707070"
];

export default {
  components: { quillEditor },
  props: {
    value: {
      type: String,
      default: ""
    },
  },
  computed: {
      editor() {
        return this.$refs.quillEditor.quill
      },
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    }
  ,

  methods: {
    insertTextAtCursor(text) {
      let selection = this.editor.getSelection(true);
      this.editor.insertText(selection.index, text);
    },

    tellBlurOccurred() {
      this.$emit("onQuillBlur");
    }
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
	 	        ["bold", "italic", "underline"],
            [{ "list": "ordered" }, { "list": "bullet" }],
            [{ "script": "sub" }, { "script": "super" }],
            [{ "header": [1, 2, 3] }],
            [{ "size": ["small", false] }],
            [{ "color": colors }, { "background":  colors}],
            [{ "align": [] }],
            ["link", "image"]
          ],
          imageResize: {},
        },
      },
    };
  },
};
</script>
